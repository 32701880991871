<template>
  <div>
    <b-row style="margin:3rem!important;" class="text-left m-5">
      <div data-custom-class="body">
        <div><strong><span style="font-size: 26px;"><span
            data-custom-class="title">PRIVACY NOTICE</span></span></strong></div>
        <div><br></div>
        <div><span style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
            data-custom-class="subtitle">Last updated <bdt
            class="question">August 07, 2022</bdt></span></span></strong></span></div>
        <div><br></div>
        <div><br></div>
        <div><br></div>
        <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">This privacy notice for <bdt
            class="question">Parrotize LLC</bdt><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
            class="block-component"></bdt></span></span> (<bdt class="block-component"></bdt>"<bdt
            class="block-component"></bdt><strong>Company</strong><bdt
            class="statement-end-if-in-editor"></bdt>," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"<bdt
            class="statement-end-if-in-editor"></bdt></span><span data-custom-class="body_text">), describes how and why we might collect, store, use, and/or share (<bdt
            class="block-component"></bdt>"<strong>process</strong>"<bdt class="statement-end-if-in-editor"></bdt>) your information when you use our services (<bdt
            class="block-component"></bdt>"<strong>Services</strong>"<bdt class="statement-end-if-in-editor"></bdt>), such as when you:</span></span></span><span
            style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span data-custom-class="body_text"><span
            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
            class="block-component"></bdt></span></span></span></span></span>
          <div>
            <bdt class="block-component"><span style="font-size: 15px;"><span style="font-size: 15px;"><span
                style="color: rgb(127, 127, 127);"><span data-custom-class="body_text"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></span></span></span></span>
            </bdt>
            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="block-component"></bdt></span></span></span></span></span>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                  style="color: rgb(127, 127, 127);"><span data-custom-class="body_text"><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <bdt
                  class="question">admin@parrotize.com</bdt>.</span></span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><strong><span style="font-size: 15px;"><span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span></span></strong>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click&nbsp;</em></strong></span></span><a
                  data-custom-class="link" href="#toc"><span style="font-size: 15px;"><span
                  data-custom-class="body_text"><strong><em>here</em></strong></span></span></a><span
                  style="font-size: 15px;"><span data-custom-class="body_text"><strong><em>&nbsp;to go directly to our table of contents.</em></strong></span></span>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with <bdt
                  class="block-component"></bdt><bdt class="question">Parrotize</bdt><bdt
                  class="statement-end-if-in-editor"></bdt> and the Services, the choices you make, and the products and features you use. Click&nbsp;</span></span><a
                  data-custom-class="link" href="#personalinfo"><span style="font-size: 15px;"><span
                  data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                  data-custom-class="body_text">&nbsp;to learn more.</span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we process any sensitive personal information?</strong> <bdt
                  class="block-component"></bdt>We do not process sensitive personal information.<bdt
                  class="else-block"></bdt></span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we receive any information from third parties?</strong> <bdt
                  class="block-component"></bdt>We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Click&nbsp;</span></span><a
                  data-custom-class="link" href="#othersources"><span style="font-size: 15px;"><span
                  data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                  data-custom-class="body_text">&nbsp;to learn more.<bdt
                  class="statement-end-if-in-editor"></bdt></span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click&nbsp;</span></span><a
                  data-custom-class="link" href="#infouse"><span style="font-size: 15px;"><span
                  data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                  data-custom-class="body_text">&nbsp;to learn more.</span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>In what situations and with which <bdt
                  class="block-component"></bdt>parties do we share personal information?</strong> We may share information in specific situations and with specific <bdt
                  class="block-component"></bdt>third parties. Click&nbsp;</span></span><a data-custom-class="link"
                                                                                           href="#whoshare"><span
                  style="font-size: 15px;"><span data-custom-class="body_text">here</span></span></a><span
                  style="font-size: 15px;"><span data-custom-class="body_text">&nbsp;to learn more.<bdt
                  class="block-component"></bdt></span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click&nbsp;</span></span><a
                  data-custom-class="link" href="#privacyrights"><span style="font-size: 15px;"><span
                  data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                  data-custom-class="body_text">&nbsp;to learn more.</span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span></span>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">Want to learn more about what <bdt
                  class="block-component"></bdt><bdt class="question">Parrotize</bdt><bdt
                  class="statement-end-if-in-editor"></bdt> does with any information we collect? Click&nbsp;</span></span><a
                  data-custom-class="link" href="#toc"><span style="font-size: 15px;"><span
                  data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                  data-custom-class="body_text">&nbsp;to review the notice in full.</span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><br></div>
              <div id="toc" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                  style="color: rgb(127, 127, 127);"><span style="color: rgb(0, 0, 0);"><strong><span
                  data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span></span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                               href="#infocollect"><span
                  style="color: rgb(89, 89, 89);">1. WHAT INFORMATION DO WE COLLECT?</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infouse"><span
                  style="color: rgb(89, 89, 89);">2. HOW DO WE PROCESS YOUR INFORMATION?<bdt
                  class="block-component"></bdt></span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a
                  data-custom-class="link"
                  href="#whoshare">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span><span
                  data-custom-class="body_text"><bdt class="block-component"></bdt></span><span
                  style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
                  class="block-component"></bdt></span></span></span></span></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#cookies"><span
                  style="color: rgb(89, 89, 89);">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a><span
                  style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
                  class="statement-end-if-in-editor"></bdt></span></span><span data-custom-class="body_text"><span
                  style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                  style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt></span></span></span></span></span></span></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                               href="#sociallogins"><span
                  style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                  style="color: rgb(89, 89, 89);">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></span></span></a><span
                  style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                  style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><bdt
                  class="statement-end-if-in-editor"></bdt></span></span><bdt
                  class="block-component"></bdt></span></span></span></span></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                               href="#intltransfers"><span
                  style="color: rgb(89, 89, 89);">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></a><span
                  style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
                  class="statement-end-if-in-editor"></bdt></span></span></span></span></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                               href="#inforetain"><span
                  style="color: rgb(89, 89, 89);">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a><span
                  style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                  style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt><bdt class="block-component"></bdt></span></span></span></span></span></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                               href="#infominors"><span
                  style="color: rgb(89, 89, 89);">8. DO WE COLLECT INFORMATION FROM MINORS?</span></a><span
                  style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
                  class="statement-end-if-in-editor"></bdt></span></span></span></span></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a
                  data-custom-class="link" href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                               href="#DNT"><span
                  style="color: rgb(89, 89, 89);">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                               href="#caresidents"><span
                  style="color: rgb(89, 89, 89);">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                               href="#policyupdates"><span
                  style="color: rgb(89, 89, 89);">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></span></div>
              <div style="line-height: 1.5;"><a data-custom-class="link" href="#contact"><span
                  style="color: rgb(89, 89, 89); font-size: 15px;">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a>
              </div>
              <div style="line-height: 1.5;"><a data-custom-class="link" href="#request"><span
                  style="color: rgb(89, 89, 89);">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></a>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div id="infocollect" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                         style="color: rgb(0, 0, 0);"><strong><span
                  data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong></span></span></span></span></span>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div id="personalinfo" style="line-height: 1.5;"><span data-custom-class="heading_2"
                                                                     style="color: rgb(0, 0, 0);"><span
                  style="font-size: 15px;"><strong>Personal information you disclose to us</strong></span></span></div>
              <div>
                <div><br></div>
                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                    style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span></span></span><span
                    data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We collect personal information that you provide to us.</em></span></span></span></span></span></span>
                </div>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you <span
                  style="font-size: 15px;"><bdt
                  class="block-component"></bdt></span>register on the Services,&nbsp;</span><span
                  style="font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px;"><bdt
                  class="statement-end-if-in-editor"></bdt></span></span><span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></span></span></span>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                  style="font-size: 15px;"><bdt class="block-component"></bdt></span></span></span></span></div>
              <div id="sensitiveinfo" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                  data-custom-class="body_text"><strong>Sensitive Information.</strong> <bdt
                  class="block-component"></bdt>We do not process sensitive information.</span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><bdt
                  class="else-block"></bdt></span></span><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                  style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"><bdt
                  class="block-component"></bdt></bdt></span></span></span></span><bdt
                  class="block-component"></bdt></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></span></span>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                  class="block-component"></bdt></span></span></span></div>
              <div style="line-height: 1.5;"><span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
                  style="font-size: 15px;"><strong>Information automatically collected</strong></span></span></div>
              <div>
                <div><br></div>
                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                    style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span></span></span><span
                    data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</em></span></span></span></span></span></span>
                </div>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></span></span>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                  class="block-component"></bdt></span></span></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar technologies. <bdt
                  class="block-component"></bdt><bdt class="block-component"></bdt></span></span></span></div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                  class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></bdt></span><span
                  data-custom-class="body_text"><bdt class="block-component"></bdt><bdt
                  class="statement-end-if-in-editor"></bdt></span><span data-custom-class="body_text"><span
                  style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                  style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
                  class="statement-end-if-in-editor"><bdt
                  class="block-component"></bdt></bdt></span></span></span></span></span></span></span><span
                  style="font-size: 15px;"><span data-custom-class="body_text"><bdt
                  class="block-component"></bdt></span></span></div>
              <div id="infouse" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                         style="color: rgb(0, 0, 0);"><strong><span
                  data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></span></span></span></span></span>
              </div>
              <div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                    style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></span></span></span></span></span></span>
                </div>
              </div>
              <div style="line-height: 1.5;"><br></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong><bdt
                  class="block-component"></bdt></span></span></span>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                    class="block-component"></bdt></span></span></span>
                  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="block-component"></bdt></span></span></span>
                    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                        class="block-component"></bdt></span></span></span>
                      <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                          class="block-component"></bdt></span></span></span>
                        <div style="line-height: 1.5;">
                          <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                          <div style="line-height: 1.5;">
                            <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                            <div style="line-height: 1.5;">
                              <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                              <div style="line-height: 1.5;">
                                <bdt class="block-component"><span style="font-size: 15px;"><span
                                    data-custom-class="body_text"></span></span></bdt>
                                <p style="font-size: 15px; line-height: 1.5;">
                                  <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                </p>
                                <p style="font-size: 15px; line-height: 1.5;">
                                  <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                </p>
                                <p style="font-size: 15px; line-height: 1.5;">
                                  <bdt class="block-component"></bdt>
                                </p>
                                <p style="font-size: 15px; line-height: 1.5;">
                                  <bdt class="block-component"></bdt>
                                </p>
                                <div style="line-height: 1.5;">
                                  <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                  <div style="line-height: 1.5;">
                                    <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                    <div style="line-height: 1.5;">
                                      <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                      <div style="line-height: 1.5;"><span style="font-size: 15px;"><bdt
                                          class="block-component"><span
                                          data-custom-class="body_text"></span></bdt></span>
                                        <div style="line-height: 1.5;">
                                          <bdt class="block-component"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text"></span></span></bdt>
                                          <div style="line-height: 1.5;">
                                            <bdt class="block-component"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"></span></span></bdt>
                                            <div style="line-height: 1.5;">
                                              <bdt class="block-component"><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text"></span></span></bdt>
                                              <div style="line-height: 1.5;">
                                                <bdt class="block-component"><span style="font-size: 15px;"><span
                                                    data-custom-class="body_text"></span></span></bdt>
                                                <div style="line-height: 1.5;">
                                                  <bdt class="block-component"><span style="font-size: 15px;"><span
                                                      data-custom-class="body_text"></span></span></bdt>
                                                  <div style="line-height: 1.5;">
                                                    <bdt class="block-component"><span style="font-size: 15px;"><span
                                                        data-custom-class="body_text"></span></span></bdt>
                                                    <div style="line-height: 1.5;">
                                                      <bdt class="block-component"><span style="font-size: 15px;"><span
                                                          data-custom-class="body_text"></span></span></bdt>
                                                      <div style="line-height: 1.5;">
                                                        <bdt class="block-component"><span
                                                            style="font-size: 15px;"><span
                                                            data-custom-class="body_text"></span></span></bdt>
                                                        <div style="line-height: 1.5;">
                                                          <bdt class="block-component"><span
                                                              style="font-size: 15px;"><span
                                                              data-custom-class="body_text"></span></span></bdt>
                                                          <div style="line-height: 1.5;">
                                                            <bdt class="block-component"><span style="font-size: 15px;"><span
                                                                data-custom-class="body_text"></span></span></bdt>
                                                            <div style="line-height: 1.5;">
                                                              <bdt class="block-component"><span
                                                                  style="font-size: 15px;"><span
                                                                  data-custom-class="body_text"></span></span></bdt>
                                                              <div style="line-height: 1.5;">
                                                                <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"></span></span></bdt>
                                                                <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"></span></span></bdt>
                                                                <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"></span></span></bdt>
                                                                <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"></span></span></bdt>
                                                              </div>
                                                              <div style="line-height: 1.5;"><br></div>
                                                              <div id="whoshare" style="line-height: 1.5;"><span
                                                                  style="color: rgb(127, 127, 127);"><span
                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  id="control"
                                                                  style="color: rgb(0, 0, 0);"><strong><span
                                                                  data-custom-class="heading_1">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></span></span></span></span></span>
                                                              </div>
                                                              <div style="line-height: 1.5;"><br></div>
                                                              <div style="line-height: 1.5;"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may share information in specific situations described in this section and/or with the following <bdt
                                                                  class="block-component"></bdt>third parties.</em></span></span></span>
                                                              </div>
                                                              <div style="line-height: 1.5;"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text"><bdt
                                                                  class="block-component"></bdt></span></span></span>
                                                              </div>
                                                              <div style="line-height: 1.5;"><br></div>
                                                              <div style="line-height: 1.5;"><span
                                                                  style="font-size: 15px;"><span
                                                                  data-custom-class="body_text">We <bdt
                                                                  class="block-component"></bdt>may need to share your personal information in the following situations:</span></span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5;"><span
                                                                  style="font-size: 15px;"><span
                                                                  data-custom-class="body_text"><bdt
                                                                  class="block-component"></bdt></span></span>
                                                                <div style="line-height: 1.5;"><span
                                                                    style="font-size: 15px;"><bdt
                                                                    class="block-component"><span
                                                                    data-custom-class="body_text"></span></bdt></span>
                                                                </div>
                                                                <ul>
                                                                  <li style="line-height: 1.5;"><span
                                                                      style="font-size: 15px;"><span
                                                                      data-custom-class="body_text"><strong>Affiliates.&nbsp;</strong>We may share your information with our affiliates, in which case we will require those affiliates to <bdt
                                                                      class="block-component"></bdt>honor<bdt
                                                                      class="statement-end-if-in-editor"></bdt> this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</span><bdt
                                                                      class="statement-end-if-in-editor"><span
                                                                      data-custom-class="body_text"></span></bdt></span>
                                                                  </li>
                                                                </ul>
                                                                <div style="line-height: 1.5;">
                                                                  <bdt class="block-component"><span
                                                                      style="font-size: 15px;"><span
                                                                      data-custom-class="body_text"></span></span></bdt>
                                                                </div>
                                                                <ul>
                                                                  <li style="line-height: 1.5;"><span
                                                                      data-custom-class="body_text"><span
                                                                      style="font-size: 15px;"><strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</span></span>
                                                                    <bdt class="statement-end-if-in-editor"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                                    </bdt>
                                                                  </li>
                                                                </ul>
                                                                <div style="line-height: 1.5;">
                                                                  <bdt class="block-component"><span
                                                                      style="font-size: 15px;"><span
                                                                      data-custom-class="body_text"></span></span></bdt>
                                                                  <div style="line-height: 1.5;">
                                                                    <bdt class="block-component"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                                    </bdt>
                                                                    <div style="line-height: 1.5;">
                                                                      <bdt class="block-component"><span
                                                                          style="font-size: 15px;"><span
                                                                          data-custom-class="body_text"></span></span>
                                                                      </bdt>
                                                                      <span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                          style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><bdt
                                                                          class="block-component"><span
                                                                          data-custom-class="heading_1"></span></bdt></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="cookies" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        id="control"
                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                        data-custom-class="heading_1">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice<span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt>.</span><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span><span
                                                                        data-custom-class="body_text"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="sociallogins" style="line-height: 1.5;">
                                                                      <span style="color: rgb(127, 127, 127);"><span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          id="control"
                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                          data-custom-class="heading_1">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</em></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform. <span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.<span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span><bdt
                                                                        class="block-component"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></bdt></span></span></span></span></span></span></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="intltransfers" style="line-height: 1.5;">
                                                                      <span style="color: rgb(127, 127, 127);"><span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          id="control"
                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                          data-custom-class="heading_1">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We may transfer, store, and process your information in countries other than your own.</em></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">Our servers are located in<span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="forloop-component"></bdt></span></span></span><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">. If you are accessing our Services from outside</span><span
                                                                        data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="forloop-component"></bdt></span></span></span></span><span
                                                                        data-custom-class="body_text">, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see <bdt
                                                                        class="block-component"></bdt>"<bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span></span><a
                                                                        data-custom-class="link" href="#whoshare"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></span></a><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt>"<bdt
                                                                        class="statement-end-if-in-editor"></bdt> above), in</span></span></span><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="forloop-component"></bdt></span></span></span><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">&nbsp;and other countries.</span></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.<span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></span></span></span></span></span><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></span></span><span
                                                                        data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></span></span></span><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="inforetain" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        id="control"
                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                        data-custom-class="heading_1">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We keep your information for as long as necessary to <bdt
                                                                        class="block-component"></bdt>fulfill<bdt
                                                                        class="statement-end-if-in-editor"></bdt> the purposes outlined in this privacy notice unless otherwise required by law.</em></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than <span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt><bdt
                                                                        class="question">6 Months</bdt><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span></span>.</span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or <bdt
                                                                        class="block-component"></bdt>anonymize<bdt
                                                                        class="statement-end-if-in-editor"></bdt> such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<span
                                                                        style="color: rgb(89, 89, 89);"><bdt
                                                                        class="block-component"></bdt></span><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="infominors" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        id="control"
                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                        data-custom-class="heading_1">8. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</em></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt><bdt
                                                                        class="question">admin@parrotize.com</bdt><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span>.<span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="privacyrights" style="line-height: 1.5;">
                                                                      <span style="color: rgb(127, 127, 127);"><span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          id="control"
                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                          data-custom-class="heading_1">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;<span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><em><bdt
                                                                        class="block-component"></bdt></em></span></span>&nbsp;</span>You may review, change, or terminate your account at any time.</em><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><bdt
                                                                        class="block-component"></bdt></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);">&nbsp;</span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="color: rgb(48, 48, 241);"><span
                                                                        data-custom-class="body_text"><a
                                                                        data-custom-class="link"
                                                                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                        rel="noopener noreferrer" target="_blank"><span
                                                                        style="font-size: 15px;">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span></span></span>.</span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">If you are located in Switzerland, the contact details for the data protection authorities are available here: <span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="color: rgb(48, 48, 241);"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><a
                                                                        data-custom-class="link"
                                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                        rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span></span></span></span></span></span>.</span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="withdrawconsent" style="line-height: 1.5;">
                                                                      <span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          data-custom-class="body_text"><strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information,<bdt
                                                                          class="block-component"></bdt> which may be express and/or implied consent depending on the applicable law,<bdt
                                                                          class="statement-end-if-in-editor"></bdt> you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <bdt
                                                                          class="block-component"></bdt>"<bdt
                                                                          class="statement-end-if-in-editor"></bdt></span></span></span><a
                                                                        data-custom-class="link" href="#contact"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></span></a><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt>"<bdt
                                                                        class="statement-end-if-in-editor"></bdt> below<bdt
                                                                        class="block-component"></bdt>.</span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,<bdt
                                                                        class="block-component"></bdt> when applicable law allows,<bdt
                                                                        class="statement-end-if-in-editor"></bdt> will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.<bdt
                                                                        class="block-component"></bdt></span></span>
                                                                      <bdt class="block-component"><span
                                                                          style="font-size: 15px;"><span
                                                                          data-custom-class="body_text"></span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="heading_2"><strong>Account Information</strong></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">If you would at any time like to review or change the information in your account or terminate your account, you can:<bdt
                                                                        class="forloop-component"></bdt></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></span>
                                                                      <bdt class="statement-end-if-in-editor"><span
                                                                          style="font-size: 15px;"><span
                                                                          data-custom-class="body_text"></span></span>
                                                                      </bdt>
                                                                      <span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          data-custom-class="body_text"><span
                                                                          style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><span
                                                                          data-custom-class="body_text"><span
                                                                          style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><bdt
                                                                          class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span>
                                                                      <bdt class="block-component"><span
                                                                          style="font-size: 15px;"><span
                                                                          data-custom-class="body_text"></span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="DNT" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        id="control"
                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                        data-custom-class="heading_1">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (<bdt
                                                                        class="block-component"></bdt>"DNT"<bdt
                                                                        class="statement-end-if-in-editor"></bdt>) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for <bdt
                                                                        class="block-component"></bdt>recognizing<bdt
                                                                        class="statement-end-if-in-editor"></bdt> and implementing DNT signals has been <bdt
                                                                        class="block-component"></bdt>finalized<bdt
                                                                        class="statement-end-if-in-editor"></bdt>. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="caresidents" style="line-height: 1.5;">
                                                                      <span style="color: rgb(127, 127, 127);"><span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          id="control"
                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                          data-custom-class="heading_1">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">California Civil Code Section 1798.83, also known as the <bdt
                                                                        class="block-component"></bdt>"Shine The Light"<bdt
                                                                        class="statement-end-if-in-editor"></bdt> law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g.<bdt
                                                                        class="block-component"></bdt>,<bdt
                                                                        class="statement-end-if-in-editor"></bdt> backups, etc.).<span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="policyupdates" style="line-height: 1.5;">
                                                                      <span style="color: rgb(127, 127, 127);"><span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          id="control"
                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                          data-custom-class="heading_1">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated <bdt
                                                                        class="block-component"></bdt>"Revised"<bdt
                                                                        class="statement-end-if-in-editor"></bdt> date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="contact" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        id="control"
                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                        data-custom-class="heading_1">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">If you have questions or comments about this notice, you may <span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt>email us at <bdt
                                                                        class="question">admin@parrotize.com</bdt><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">&nbsp;or by post to:</span></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="question">33 Nassau Avenue, Brooklyn, NY 11222</bdt></span></span></span></span></span><span
                                                                        data-custom-class="body_text"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"></bdt></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="question">Parrotize LLC</bdt><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><bdt
                                                                        class="block-component"></bdt></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="question">11222</bdt><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><bdt
                                                                        class="block-component"></bdt><bdt
                                                                        class="block-component"></bdt><bdt
                                                                        class="block-component"></bdt></span></span></span><span
                                                                        data-custom-class="body_text"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span></span></span><span
                                                                        data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="color: rgb(89, 89, 89);"><bdt
                                                                        class="statement-end-if-in-editor"><span
                                                                        style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="block-component"><bdt
                                                                        class="block-component"></bdt></bdt></span></span></span></bdt></span></span></span><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="statement-end-if-in-editor"><bdt
                                                                        class="block-component"></bdt></bdt></span></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div id="request" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        id="control"
                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                        data-custom-class="heading_1">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><br></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please <bdt
                                                                        class="block-component"></bdt>submit a request form by clicking&nbsp;</span><span
                                                                        style="color: rgb(48, 48, 241);"><span
                                                                        data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><a
                                                                        data-custom-class="link"
                                                                        href="https://parrotize.com"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank">here</a> and fill out the contact form below the page</span></span></span><span
                                                                        style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><bdt
                                                                        class="statement-end-if-in-editor"></bdt></span></span><span
                                                                        data-custom-class="body_text">.</span></span></span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <b-row class="mt-5 pb-5 " style=" background-color: #100C1E">

      <b-col class="footerwrapper" cols="9" lg="9" md="9" offset="1" sm="13">
        <b-row>
          <b-col class="pt-5" lg="8" md="12" xl="6">
            <p style="text-align: left;
                  padding-top: 0.5rem;
                  vertical-align: middle;
                  font: normal normal normal 20px/27px Nunito;
                  letter-spacing: 0px;
                  color: #FFF9EA;
                   opacity: 1;">
              We are offering the best plans for your company, events and conferences.
            </p>
          </b-col>
          <b-col class="pt-5 justify-content-left text-left " lg="4" md="12" xl="2">
            <b-button class="mr-3 underline px-4" href="https://app.parrotize.com/order" pill
                      style="background-color: #FF4803!important;color: white!important;"
                      variant="transparent">
              Create Event
            </b-button>
          </b-col>
          <b-col class="pt-5 justify-content-left text-left " lg="4" md="12" xl="4">
            <b-button v-b-modal.contactform class="mr-3 underline px-4" pill
                      style="background-color: #FF4803!important;color: white!important;"
                      variant="transparent">
              Contact us
            </b-button>
            <b-modal id="contactform" hide-footer size="l" title="Contact Us">
              <contactform/>
            </b-modal>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pt-5" lg="3" md="6" xl="2">
            <p style="text-align: left;
                  padding-top: 0.5rem;
                  vertical-align: middle;
                  font: normal normal normal 20px/27px Nunito;
                  letter-spacing: 0px;
                  color: #FFF9EA;
                   opacity: 1;">
              Follow Us!
            </p>
          </b-col>
          <b-col class="pt-5 justify-content-left text-left " lg="9" md="6" xl="10">
            <b-button aria-label="linkedin button" class="mr-3 px-1" href="https://tr.linkedin.com/company/parrotize"
                      rel="noopener " target="_blank"
                      variant="transparent">
              <linkedin-icon style="color: white"></linkedin-icon>
            </b-button>
            <b-button aria-label="twitter button" class="mr-3 px-1" href="https://twitter.com/ParrotizeApp"
                      rel="noopener " target="_blank"
                      variant="transparent">
              <twitter-icon style="color: white;"></twitter-icon>
            </b-button>
            <b-button aria-label="instagram button" class="mr-3 px-1" href="https://www.instagram.com/parrotizecom/"
                      rel="noopener "
                      target="_blank" variant="transparent">
              <instagram-icon style="color: white"></instagram-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-row class="pt-5" lg="2" md="6" xl="1">
            <b-button v-b-modal.contactform class="mr-3 footerlinks pl-2" variant="transparent">
              Support
            </b-button>
            <b-button class="mr-3 footerlinks px-1" :to="{ path: `/termsandconditions` }" variant="transparent">
              Terms & conditions
            </b-button>
            <b-button :to="{ path: `/privacy` }" class="mr-3 footerlinks px-1" variant="transparent">
              Privacy policy
            </b-button>
          </b-row>

        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import contactform from "../components/contactform.vue";
import {AirplayIcon, AtSignIcon, TwitterIcon, InstagramIcon, LinkedinIcon} from "vue-feather-icons";

import {

  BRow,
  BCol,
  BModal,
  BButton,
  VBModal

} from "bootstrap-vue";

export default {
  components: {
    contactform,
    AirplayIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
    BRow,
    BModal,
    BCol,
    BButton
  },
  directives: {
    'b-modal': VBModal
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
h3, ul, li, p {
  text-align: left;
}

li, p {
  color: #656565
}

h3 {
  font-weight: 700;
}

[data-custom-class='body'], [data-custom-class='body'] * {
  background: transparent !important;
}

[data-custom-class='title'], [data-custom-class='title'] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}

[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}

[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}

[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}

[data-custom-class='body_text'], [data-custom-class='body_text'] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

[data-custom-class='link'], [data-custom-class='link'] * {
  color: #3030F1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}

ul {
  list-style-type: square;
}

ul > li > ul {
  list-style-type: circle;
}

ul > li > ul > li > ul {
  list-style-type: square;
}

ol li {
  font-family: Arial;
}
</style>
